import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table common--block common--block-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_header = _resolveComponent("table-header")!
  const _component_table_container = _resolveComponent("table-container")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_table_header, {
      user: _ctx.user,
      options: _ctx.options,
      onOnSearch: _ctx.onSearch
    }, null, 8, ["user", "options", "onOnSearch"]),
    _createVNode(_component_table_container, {
      user: _ctx.user,
      data: _ctx.data,
      options: _ctx.options,
      query: _ctx.query,
      pending: _ctx.pending
    }, null, 8, ["user", "data", "options", "query", "pending"]),
    (_ctx.total > _ctx.query.limit)
      ? (_openBlock(), _createBlock(_component_pagination, {
          key: 0,
          onSelectPage: _ctx.selectPage,
          total: _ctx.total,
          query: _ctx.query
        }, null, 8, ["onSelectPage", "total", "query"]))
      : _createCommentVNode("", true)
  ]))
}