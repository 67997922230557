
import { defineComponent } from "vue-demi";
import { EditIcon, TrashIcon, CheckMarkIcon, DownArrowIcon } from "@/plugins/icons";
import { dynamicsObject } from "@/interfaces";
import { Order } from "@/interfaces/order/order.dto";
import { PRODUCT_TYPES, STATUS_TYPES } from "@/utils/enums";
import ContainerBodyElement from "./ContainerBodyElement.vue";
import { Cart } from "@/interfaces/cart/cart.dto";

export default defineComponent({
  name: "TableContainerBody",
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showRow: null as dynamicsObject | null,
    }
  },
  methods: {
    currentPriceProduct(product: Cart.Dto['products'][number]) {
      const price = product.product?.type === PRODUCT_TYPES.UNIT ? product.product?.pricePerUnit : product.product?.pricePerPack;
      if (!price) return '--';
      return product.quantity * price;
    },
    modal(id: string, data: dynamicsObject) {
      this.$store.commit('createModal', { id, data });
    },
    isOrderNotProcessed(order: Order.Dto) {
      return this.options.id === 'admin-order' && order.status === STATUS_TYPES.PAID;
    },
    onShowRow(data: dynamicsObject) {
      console.log(data);
      if (this.showRow === data._id) return this.showRow = null;
      this.showRow = data._id;
    }
  },
  components: {
    ContainerBodyElement,
    EditIcon,
    CheckMarkIcon,
    TrashIcon,
    DownArrowIcon,
  },
});
