import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ae1a89c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container_header = _resolveComponent("container-header")!
  const _component_container_preload_body = _resolveComponent("container-preload-body")!
  const _component_container_body = _resolveComponent("container-body")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createVNode(_component_container_header, {
        header: _ctx.options.header,
        query: _ctx.query,
        id: _ctx.options.id
      }, null, 8, ["header", "query", "id"]),
      (_ctx.pending)
        ? (_openBlock(), _createBlock(_component_container_preload_body, {
            key: 0,
            variables: _ctx.options.variables
          }, null, 8, ["variables"]))
        : (_openBlock(), _createBlock(_component_container_body, {
            key: 1,
            data: _ctx.data,
            options: _ctx.options
          }, null, 8, ["data", "options"]))
    ])
  ]))
}