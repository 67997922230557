
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { dynamicsObject } from "@/interfaces";

import TableHeader from "./modules/header/index.vue";
import TableContainer from "./modules/container/index.vue";
import Pagination from "./modules/Pagination.vue";

export default defineComponent({
  name: "TableMain",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      data: [] as Array<dynamicsObject>,
      total: 0,
      query: {
        limit: this.options.limit || 15,
        page: 1,
        search: "",
      },
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getTableData() {
      this.pending = true;
      const result = await this.API.table.getTableData(this.options.request, {
        params: this.query,
      });
      this.data = result.data;
      this.total = result.total;
      this.pending = false;
    },
    onSearch(search: string) {
      this.query.search = search;
      this.getTableData();
    },
    selectPage(page: string) {
      this.data = [];
      this.query.page = Number(page);
      this.getTableData();
    },
    // Emitter
    onEmits() {
      this.emitter.on(this.options.id, this.tableCreatedEmitter);
      this.emitter.on(this.options.id + '-edit', this.tableUpdatedEmitter);
      this.emitter.on(this.options.id + '-delete', this.tableDeletedEmitter);
    },
    offEmits() {
      this.emitter.off(this.options.id, this.tableCreatedEmitter);
      this.emitter.off(this.options.id + '-edit', this.tableUpdatedEmitter);
      this.emitter.off(this.options.id + '-delete', this.tableDeletedEmitter);
    },
    tableCreatedEmitter(data: dynamicsObject) {
      this.data = [...this.data, data];
    },
    tableUpdatedEmitter(data: dynamicsObject) {
      const currentDoc = this.data.find(e => e._id === data._id);
        if (currentDoc)
          for (const key in data) currentDoc[key] = data[key];
    },
    tableDeletedEmitter(data: string) {
      this.data = this.data.filter(e => e._id !== data);
    },
  },
  components: {
    TableHeader,
    TableContainer,
    Pagination,
  },
});
