
import { defineComponent } from "vue-demi";
import CommonButton from '@/components/common/button/Button.vue';
import { PlusIcon } from '@/plugins/icons';

export default defineComponent({
  emits: ['onSearch'],
  name: 'TableHeader',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  methods: {
    onSearch(search: string) {
      this.$emit("onSearch", search);
    },
    modal() {
      this.$store.commit('createModal', { id: this.options.id, data: {} });
    },
  },
  components: {
    CommonButton,
    PlusIcon
  }
})
