import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (element) => {
        return (_openBlock(), _createElementBlock("th", {
          key: element.id,
          style: _normalizeStyle(`width: ${element.width}`)
        }, [
          _createElementVNode("span", null, _toDisplayString(element.name), 1)
        ], 4))
      }), 128))
    ])
  ]))
}