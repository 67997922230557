
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "TableContainerHeader",
  props: {
    header: {
      type: Array,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
});
