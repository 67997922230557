
import dateFilter from "@/filters/date.filter";
import { Cart } from "@/interfaces/cart/cart.dto";
import { Order } from "@/interfaces/order/order.dto";
import { PRODUCT_TYPES, ROLES } from "@/utils/enums";
import { PRODUCT_TYPES_NAMES, STATUS_NAMES } from "@/utils/enums/names";
import { currentImageSize } from "@/utils/files";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "TableContainerBodyElement",
  props: {
    variable: {
      type: String,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentText() {
      let text = this.element[this.variable];
      if (this.table === 'admin-store-product' && this.element.product) {
        text = this.element.product[this.variable];
        if (this.variable === 'quantity') return this.element.quantity;
        if (this.variable === 'store') return this.element.store?.address;
      }
      if (this.variable === 'productLength') {
        return this.element.products.length;
      }
      if (this.variable.includes('__')) {
        const split = this.variable.split('__');
        if (split[0] === 'user' && split[1] === 'name') return this.element?.user?.name || this.element.name || '--';
        if (split[0] === 'user' && split[1] === 'phone') return this.element?.user?.phone || this.element.phone || '--';
        if (this.element[split[0]] && this.element[split[0]][split[1]]) return this.element[split[0]][split[1]];
        return '--';
      }
      if (this.variable === 'status') {
        return `<span class="alsb ${text}">${STATUS_NAMES[(text as keyof typeof STATUS_NAMES)]}</span>`
      }
      if (this.variable === 'createdAt' || this.variable === 'date') {
        return dateFilter(text);
      }
      if ((this.variable === 'image' || this.variable === 'avatar') && text) {
        if (Array.isArray(text)) return text[0] ? `<img src="${text[0].src}" alt="Image">` : '——';
        return `<img src="${currentImageSize(text.src, 100, 100)}" alt="Image">`
      }
      if (this.variable === 'track' && this.element.delivery?.track) {
        return `<a href="https://www.cdek.ru/ru/tracking?order_id=${this.element.delivery?.track}" target="_blank">${this.element.delivery?.track}</a>`; 
      }
      if (this.variable === 'partner') {
        return text === ROLES.PARTNER ? 'Да' : 'Нет';
      }
      if (this.variable === 'orderLength') {
        return this.element.orders.length;
      }
      if (this.variable === 'orderAmount') {
        return this.element.orders.reduce((acc: number, order: { amount: number }) => acc + order.amount, 0);
      }
      if (this.variable === 'referralAmount') {
        return this.element.orders.reduce((acc: number, order: { amount: number }) => acc + order.amount, 0) * 0.1;
      }
      if (this.variable === 'orderNumberDate') {
        return `Заказ №${this.element.number} от ${dateFilter(this.element.createdAt)}`;
      }
      if (this.variable === 'quantityOrder') {
        return this.element.products.reduce((acc: number, product: Order.Dto['products'][number]) => acc + product.quantity, 0);
      }
      if (this.variable === 'priceOrder') {
        return this.priceOrder(this.element.products);
      }
      if (this.variable === 'amountOrder') {
        return this.totalOrder(this.element.products) + Number(this.element.delivery.price);
      }
      if (this.variable === 'deliveryOrder') {
        return this.element.delivery.price;
      }
      if (this.variable === 'discount') {
        return this.discountOrder(this.element.products);
      }
      if (this.variable === 'amountWithDiscount') {
        return this.element.amount;
      }
      if (this.variable === 'bonus') {
        return this.element.amount * 0.1;
      }
      if (this.variable === 'type' && this.table === 'admin-catalog') {
        return PRODUCT_TYPES_NAMES[this.element.type as PRODUCT_TYPES];        
      }
      if (this.variable === 'referrer') {
        return text ? 'Да' : 'Нет';
      }
      if (this.variable === "category")
        return text?.name || "--";
      if ((this.variable.includes('price') || this.variable.includes('amount')) && text)
        return `<div class="abls">${text} ₽</div>`
      return text || "——";
    },
    currentClass() {
      return "";
    },
  },
  methods: {
    totalOrder(products: Order.Dto['products']) {
      return products.reduce((acc, p) => {
        const amountProduct = p.product.type === PRODUCT_TYPES.UNIT ? p.product.pricePerUnit : p.product.pricePerPack;
        return acc + (amountProduct * p.quantity);
      }, 0);
    },
    priceOrder(products: Order.Dto['products']) {
      return products.reduce((acc, p) => {
        const amountProduct = p.product.type === PRODUCT_TYPES.UNIT ? p.product.pricePerUnit : p.product.pricePerPack;
        return acc + amountProduct;
      }, 0);
    },
    discountOrder(order: Order.Dto) {
      let discount = 0;
      if (this.element.certificate) discount += order.certificate.price;
      if (this.element.promocode) discount += (this.totalOrder(order.products) * 0.1);
      return discount;
    }
  }
});
