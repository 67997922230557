
import { defineComponent } from "vue-demi";
import ContainerBody from "./modules/ContainerBody.vue";
import ContainerPreloadBody from "./modules/ContainerPreloadBody.vue";
import ContainerHeader from "./modules/ContainerHeader.vue";

export default defineComponent({
  name: "TableContainer",
  props: {
    options: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pending: false
    }
  },
  components: {
    ContainerHeader,
    ContainerBody,
    ContainerPreloadBody,
  },
});
